import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import data from "../../data";
import Card from "./HowToCard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function HowTo() {
  const classes = useStyles();
  
  return (
    <div
      style={{
        padding: 40,
        width: "100vw",
        backgroundColor: "#FAFAFA",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h3
        style={{
          fontFamily: "Rubik",
          fontStyle: "normal",
          color: "#6FCF97",
          fontWeight: "bold",
          marginTop: 30,
          fontSize: "16px",
          letterSpacing: "0.1em"
        }}
      >
        HOW TO VOLUNTEER
      </h3>
      <p
        style={{
          fontWeight: "normal",
          fontFamily: "Rubik",
          fontStyle: "normal",
          width: "50%",
          textAlign: "center",
          fontSize: "24px",
          marginBottom: 50,
        }}
      >
        Easy steps to becoming a volunteer
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: window.screen.width > 600 ? "space-between" : "center",
          width: "100%",
          paddingRight: "10%",
          paddingLeft: "10%",
          marginTop: 20,
        }}
      >
        <Grid
          container
          className={classes.root}
          xs={1}
          sm={12}
          md={12}
          spacing={2}
        >
          {data.howto.map((info) => (
            <Grid
              key={info.id}
              item
              style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}
              xs={1}
              sm={6}
              md={6}
              lg={4}
            >
              <Card
                id={info.id}
                message={info.message}
                className={classes.paper}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default HowTo;
