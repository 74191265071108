import React from 'react'
import {Others as Header} from '../../header'
import CategoryHeader from "./header"
import Body from "./body"

export default function index() {
    return (
        <div style={{backgroundColor: "#FAFAFA", height: "100%", minHeight: "100vh"}}>
            <Header />
            <CategoryHeader />
            <Body />
        </div>
    )
}
