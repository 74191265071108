import React from "react";
import Button from "components/CustomButtons/Button";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import MobileMenu from "./MobileMenu";
import "../../Main.css";

function NavBar() {
  if (window.screen.width < 600) {
    return (
      <div style={{paddingTop: "4%" }}>
        <MobileMenu />
      </div>
    );
  }

  return (
    <div id="nav-div">
      <nav>
        <ScrollLink smooth={true} to={"about"} className="nav-link" href="">
          Who we are
        </ScrollLink>
        <ScrollLink smooth={true} to={"howto"} className="nav-link" href="">
          How to volunteer
        </ScrollLink>
        <ScrollLink
          smooth={true}
          to={"categories"}
          className="nav-link"
          href=""
        >
          Categories
        </ScrollLink>
        <Link to={"/faq"} className="nav-link">
          FAQ
        </Link>
      </nav>
      <div>
        <Link to={"/signup"}>
          <Button
            type="button"
            style={{ backgroundColor: "white" }}
            id="signup-link"
          >
            Sign Up
          </Button>
        </Link>
        <Link to={"/login"}>
          <Button type="button" style={{ backgroundColor: "#6FCF97" }}>
            Sign In
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default NavBar;
