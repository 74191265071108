export default {
  howto: [
    {
      id: 1,
      message:
        "Sign up to be able to apply for opportunities you might be interested in",
    },
    // {
    //   id: 2,
    //   message:
    //     "Browse through opportunities to get the one that suits you perfectly",
    // },
    // {
    //   id: 3,
    //   message:
    //     "Read through to confirm you are capable of beign efficient in the chosen opportunity",
    // },
    {
      id: 2,
      message:
        "Apply for the opportunity after confirming you will be efficient for the opportunity",
    },
    {
      id: 3,
      message:
        "Wait to be approved for a while to be sure you are fit to work in that capacity",
    },
    // {
    //   id: 6,
    //   message:
    //     "It's finally time to start volunteering as the amaizing person that you are",
    // },
  ],
  categories: [
    {
      id: 1,
      title: "DIGITAL",
      message:
        "Sign up to be able to apply for opportunities you might be interested in",
    },
    {
      id: 2,
      title: "FOOD AND AGRICULTURE",
      message:
        "Browse through opportunities to get the one that suits you perfectly",
    },
    {
      id: 3,
      title: "ENVIRONMENT",
      message:
        "Read through to confirm you are capable of beign efficient in the chosen opportunity",
    },
    {
      id: 4,
      title: "HEALTH CARE",
      message:
        "Apply for the opportunity after confirming you will be efficient for the opportunity",
    },
    {
      id: 5,
      title: "FINANCE",
      message:
        "Wait to be approved for a while to be sure you are fit to work in that capacity",
    },
    {
      id: 6,
      title: "EDUCATION",
      message:
        "It's finally time to start volunteering as the amaizing person that you are",
    },
    {
      id: 7,
      message:
        "It's finally time to start volunteering as the amaizing person that you are",
    },
  ],
  applications: {
    approved: [
      {
        id: 1,
        message:
          "Sign up to be able to apply for opportunities you might be interested in",
      },
      {
        id: 2,
        message:
          "Browse through opportunities to get the one that suits you perfectly",
      },
      {
        id: 3,
        message:
          "Read through to confirm you are capable of beign efficient in the chosen opportunity",
      },
    ],
    declined: [
      {
        id: 1,
        message:
          "Sign up to be able to apply for opportunities you might be interested in",
      },
      {
        id: 2,
        message:
          "Browse through opportunities to get the one that suits you perfectly",
      },
    ],
    pending: [
      {
        id: 1,
        message:
          "Sign up to be able to apply for opportunities you might be interested in",
      },
      {
        id: 2,
        message:
          "Browse through opportunities to get the one that suits you perfectly",
      },
      {
        id: 3,
        message:
          "Read through to confirm you are capable of beign efficient in the chosen opportunity",
      },
    ],
  },
  gender: [
    { id: 1, name: "Male" }, 
    { id: 2, name: "Female" }
  ],
  qualifications: [
    {id: 1, name: "O Level"},
    {id: 2, name: "SSCE"},
    {id: 3, name: "A Level"},
    {id: 4, name: "OND"},
    {id: 5, name: "Nigerian Certificate of Education"},
    {id: 6, name: "HND"},
    {id: 7, name: "Bachelor's degree"},
    {id: 8, name: "Doctor of Veterinary Medicine"},
    {id: 9, name: "Postgraduate Diploma"},
    {id: 10, name: "Master's Degree"},
    {id: 11, name: "Master of Philosophy"},
    {id: 12, name: "Doctor of Philosophy"}
  ],
  lga: [
    {id: 1, name: "No LGA found"}
  ]
};
