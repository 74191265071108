import React from "react";

function headerRight() {
  return (
    <div>
      <h3
        style={{
          fontFamily: "Rubik",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "22px",
          color: "#5E6C84",
        }}
      >
        Title of the Opportunity
      </h3>
      <h5
        style={{
          fontFamily: "Rubik",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "16px",
          color: "#6FCF97",
        }}
      >
        CATEGORY
      </h5>
    </div>
  );
}

export default headerRight;
