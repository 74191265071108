import React from "react";
import Navbar from "./NavBar";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import "../../Main.css";

export default function index() {
  return (
    <div lg={12} xl={12} style={{}} id="header">
      <Link to={"/"}>
        <img src={require("assets/img/logo1.png")} alt="logo" />
      </Link>
      <Navbar />
    </div>
  );
}


