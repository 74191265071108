import React from "react";
import { Auth as Header } from "../header";
import Login from "./Login";
import "../Main.css";

export default function index() {
  if (window.screen.width < 600) {
    return (
      <div style={{ backgroundColor: "#FAFAFA", height: '100vh' }}>
        <Header />
        <Login />
      </div>
    );
  }
  return (
    <div style={{ backgroundColor: "#FAFAFA", height: "100vh" }}>
      <Header />
      <div className="auth-component">
        <img src={require("assets/img/login-image.png")} alt="login" />
        <div className="divider"></div>
        <Login />
      </div>
    </div>
  );
}
