import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CheckCircleSharpIcon from "@material-ui/icons/CheckCircleSharp";
import CancelSharpIcon from "@material-ui/icons/CancelSharp";
import Typography from "@material-ui/core/Typography";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    minWidth: window.screen.width > 600 ? 320 : "90vw",
    width: 375,
    height: 300,
    marginBottom: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  title: {
    fontWeight: 500,
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontSize: "20px",
    color: "#5E6C84",
  },
  pos: {
    marginBottom: 12,
    fontWeight: "normal",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontSize: "16px",
    color: "#5E6C84",
  },
  iconDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconCheck: {
    width: "31.54px",
    height: "31.54px",
    color: "#6FCF97",
    marginBottom: 25,
  },
  iconCancel: {
    width: "31.54px",
    height: "31.54px",
    color: "red",
    marginBottom: 25,
  },
});

export default function CategoriesCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <div className={classes.iconDiv}>
          <Typography
            variant="h5"
            className={classes.title}
            component="h2"
            style={{ marginBottom: 20, marginTop: 20 }}
          >
            Opportunity {props.id}
          </Typography>
          {!props.icon ? null : props.icon === "check" ? (
            <CheckCircleSharpIcon className={classes.iconCheck} />
          ) : (
            <CancelSharpIcon className={classes.iconCancel} />
          )}
        </div>
        <Typography
          className={classes.pos}
          color="textSecondary"
          style={{ marginBottom: 15 }}
        >
          A brief description of what the category is all about. It should not
          be more than 50-80 words.
        </Typography>
        <Typography
          variant="body2"
          component="p"
          color="textSecondary"
          style={{
            fontWeight: "normal",
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontSize: "14px",
            color: "#5E6C84",
          }}
        >
          6hrs/day
        </Typography>
      </CardContent>
      <Link
        to={"/categories/:category"}
        style={{
          backgroundColor: "#6FCF97",
          width: "100%",
          height: 50,
          padding: 13,
          color: "white",
          paddingLeft: 30,
          fontWeight: "bold",
        }}
      >
        Check Requirements
      </Link>
    </Card>
  );
}
