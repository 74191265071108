import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  image: {
    width: 150,
    height: 'auto',
    marginRight: window.screen.width < 600 ? "5%" : null,
    marginLeft: window.screen.width < 600 ? "5%" : null,
  },
}));

function Partners() {
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FAFAFA",
        height: "235.82px",
      }}
    >
      <h3
        style={{
          color: "#6FCF97",
          fontFamily: "Rubik",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "16px",
          marginBottom: 20,
        }}
      >
        OUR PARTNERS
      </h3>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          minWidth: "50%",
          marginBottom: 20,
        }}
      >
        <a href="http://itanna.co" target="_blank" rel="noopener noreferrer">
          <img
            className={classes.image}
            src={require("assets/img/itana.png")}
            alt=""
          />
        </a>
        <a href="http://tradebuza.com" target="_blank" rel="noopener noreferrer">
          <img
            className={classes.image}
            src={require("assets/img/tradebuza.jpeg")}
            alt=""
          />
        </a>
      </div>
    </div>
  );
}

export default Partners;
