import React from "react";
import Button from "components/CustomButtons/Button";

function headerButton() {
  return (
    <div>
      <Button
        style={{
          fontFamily: "Rubik",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "22px",
          backgroundColor: "#6FCF97",
          color: "#FAFAFA"
        }}
      >
        Apply
      </Button>
    </div>
  );
}

export default headerButton;
