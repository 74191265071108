import React from "react";
import FooterNav from "./FooterNav";
import Social from "./Social";
import "../Main.css";

export default function() {
  const breaker = () => (window.screen.width < 600 ? <br /> : null);
  return (
    <div id="footer">
      <div
        style={{
          width: "100vw",
          height: "1px",
          backgroundColor: "green",
          marginTop: 8,
        }}
      ></div>
      <img
        src={require("assets/img/logo1.png")}
        style={{
          width: "211.27px",
          height: "auto",
          marginTop: 60,
          marginBottom: 20,
        }}
        alt="footer"
      />
      <Social />
      <FooterNav />
      <div id="footer-div">
        <hr />
      </div>
      <p
        style={{
          textAlign: "center",
          color: "#5E6C84",
          fontWeight: "normal",
          fontFamily: "Rubik",
          fontStyle: "normal",
          fontSize: "14px"
        }}
      >
        &copy; Copyright {1900 + new Date().getYear()} Ogun State Volunteer{" "}
        {breaker()} Management System.
      </p>
    </div>
  );
}
