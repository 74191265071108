import React from "react";
import HeaderRight from "./headerRight";
import HeaderButton from "./headerButton";

function header() {
  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "10%",
        paddingLeft: "10%",
        marginTop: "3%",
        paddingTop: "7vh",
      }}
    >
      <HeaderRight />
      <HeaderButton />
    </div>
  );
}

export default header;
