import React from "react";
import PropTypes from "prop-types";
import SignupForm from "./SignupForm";
import { withRouter } from "react-router-dom";
import CustomAlert from "../Utils/CustomAlert";
import baseURL from "../../BaseUrl";

const Signup = (props) => {
  // const { history } = props;

  const [checked, setChecked] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [signupState, setSignupState] = React.useState({});
  const [state, setState] = React.useState({
    email: "",
    password: "",
    confirmPassword: "",
    first_name: "",
    surname: "",
    address: "",
    phone_number: "",
  });

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value.trim(),
    });
    console.log("name", name, "value", value);
  };

  const handleTagChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
    console.log("tag name", name, "tag value", value, "state", state);

  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log(date);
  };

  const handleSelectChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    console.log("name", name, "value", event.target.value, "state", state);
  };

  const handleSelectCountryChange = (name) => {
    setState({
      ...state,
      "state": name,
    });
    console.log("name", [name], "value", name, "state", state);
  }

  const callbackModal = () => {
    setSignupState({ showModal: false });
  };
 
  if (signupState.showModal) {
    return (
      <CustomAlert
        destination={signupState.destination}
        type={signupState.type}
        message={signupState.message}
        callbackModal={callbackModal}
      />
    );
  }

  const handleSignUp = async () => {
    try {
      const {
        email,
        password,
      } = state;
      let body = {
        ...state,
        date_of_birth: selectedDate,
        spoken_languages: state.spoken_languages.join()
      };
      console.log(
        "state values",
        email,
        password,
        "after",
        state.lga,
        "state",
        body
      );

      if (!checked[0]) {
        throw Error("you must tick the privacy policy to continue");
      }
      if (!email && !password) {
        throw Error("You must enter your email and password to continue");
      }
      if (!email) {
        throw Error("please enter email address");
      } else if (!password) {
        throw Error("please enter your password");
      }
      fetch(`${baseURL}/user-sign-up`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then(async(result) => {
          console.log("result", result);
          if (result.status) {
            await setSignupState({
              ...signupState,
              showModal: true,
              destination: "login",
              type: "success",
              message: result.message,
            });
          } else {
            await setSignupState({
              ...signupState,
              showModal: true,
              type: "error",
              message: result.message,
            });
          }
        })
        .catch((error) => console.log("fetch error", error));
    } catch (error) {
      await setSignupState({
        ...signupState,
        showModal: true,
        type: "error",
        message: error.message,
      });
    }
  };

  return (
    <div
      style={{
        paddingRight: window.screen.width < 600 ? "5%" : "10%",
        paddingLeft: window.screen.width < 600 ? "5%" : "10%",
        paddingTop: "5%",
        paddingBottom: "5%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: window.screen.width < 600 ? "100vw" : "50%",
      }}
    >
      <SignupForm
        handleChange={handleChange}
        handleSignUp={handleSignUp}
        handleToggle={handleToggle}
        handleTagChange={handleTagChange}
        handleDateChange={handleDateChange}
        handleSelectChange={handleSelectChange}
        handleSelectCountryChange={handleSelectCountryChange}
        selectedDate={selectedDate}
        email={state.email}
        password={state.password}
      />
    </div>
  );
};

Signup.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Signup);
