import React from "react";
import PropTypes from "prop-types";
import ProfileForm from "./ProfileForm";
import { withRouter } from "react-router-dom";
import baseURL from "../../BaseUrl";

const Profile = (props) => {
  // const { history } = props;

  const [selectedDate, setSelectedDate] = React.useState();
  const [state, setState] = React.useState({});

  React.useEffect(() => {
    delete props.data._id;
    delete props.data.createdAt;
    delete props.data.updatedAt;

    if (!state.user_role){
      setState(props.data);
    }
    
  },[props.data, state.user_role]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log(date);
  };

  const handleSelectChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    console.log("name", name, "value", event.target.value, "state", state);
  };

  const handleSelectCountryChange = (name) => {
    setState({
      ...state,
      state: name,
    });
    console.log("name", name, "value", name, "state", state);
  };

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
    console.log("name", name, "value", value);
  };

  const handleProfile = async () => {
    //     setState({ ...state, errorMessage: '' })
    try {
      const { email, password } = state;
      let body = {
        ...state,
        date_of_birth: selectedDate,
      };
      console.log(
        "state values",
        email,
        password,
        "after",
        state.lga,
        "state",
        body
      );

      fetch(`${baseURL}/use`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result);
          if (result.status) {
            // localStorage.setItem("isAuthenticated", "true");
            alert(result.message);
            // history.push("/login");
          } else {
            alert(result.message);
          }
        })
        .catch((error) => console.log("fetch error", error));
      // try {
      //   // console.log("Result", JSON.stringify(result.data));
      // alert(result.message)
      //   // await localStorage.setItem("token", JSON.stringify(result.data.token));
      // } catch (error) {
      //   console.log("async error", error);
      // }
      //         setState({ ...state, token: result.data.token })
      //         console.log('result', state.token)
    } catch (error) {
      // let errorMessage = error.response.data.message;
      //         setState({ ...state, errorMessage })
      console.log("error", error.message);
      await alert(error.message);
    }
  };

  return (
    <div
      style={{
        padding: "8%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: window.screen.width < 600 ? "100%" : "50%",
      }}
    >
      <ProfileForm
        handleChange={handleChange}
        handleProfile={handleProfile}
        handleDateChange={handleDateChange}
        handleSelectChange={handleSelectChange}
        handleSelectCountryChange={handleSelectCountryChange}
        selectedDate={selectedDate}
        data={state}
      />
    </div>
  );
};

Profile.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Profile);
