import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "45%",
    marginTop: 6,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function SignupSelect(props) {
  const classes = useStyles();
  // const [state, setstate] = React.useState({});
  return (
    <FormControl className={classes.formControl}>
      <InputLabel
        htmlFor="custom-selct"
        style={{
          fontSize: "16px",
          fontWeight: "normal",
          fontFamily: "Rubik",
          fontStyle: "normal",
          color: "rgba(94, 108, 132, 0.5)",
        }}
      >
        {props.hasValue ? null : props.title}
      </InputLabel>
      <Select
        native
        value={props.value}
        onChange={props.handleChange}
        inputProps={{
          name: props.id,
          id: "custom-select",
        }}
        style={{}}
      >
        <option aria-label="None" value="">
          {props.hasValue ? props.title : null}
        </option>

        {props.data.map((element) => (
          <option key={element.name} value={element.name}>{element.name}</option>
        ))}
      </Select>
    </FormControl>
  );
}

export default SignupSelect;
