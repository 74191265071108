/* eslint-disable no-use-before-define */
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: 500,
    // '& > * + *': {
    //   marginTop: theme.spacing(3),
    // },
  },
}));

export default function Tags(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        freeSolo
        id="tags-standard"
        options={defaultLanguages.map((option) => option.title)}
        defaultValue={props.hasValue ? props.value : []}
        onChange={(event, value) => props.handleChange(props.id, value.map(el => el))}
        renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
        renderInput={(params) => (<TextField
            {...params}
            variant="standard"
            label={props.hasValue ? null : props.label}
            placeholder="Enter spoken languages"
          />
        )}
      />
    </div>
  );
}


const defaultLanguages = [
  { title: 'English', id: 1 },
  { title: 'Yoruba', id: 2 },
  { title: 'Igbo', id: 3 },
  { title: 'Hausa', id: 4 },
];
