import React from "react";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
// react components for routing our app without refresh
import { Link, withRouter } from "react-router-dom";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
// import baseURL from "../../BaseUrl";

const ResetPassword = (props) => {
  const { history } = props;

  const [state, setState] = React.useState({ email: ""});
  const handleChange = async (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };
  const handleReset = async () => {
    //     setState({ ...state, errorMessage: '' })
    try {
      const { email } = state;
      console.log("state value", email);

      if (!email) {
        throw Error("please enter email address");
      } 
      // fetch(`${baseURL}/user-sign-in`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ email }),
      // })
      //   .then((response) => response.json())
      //   .then((result) => {
      //     console.log("result", result);
      //     if (result.status) {
      //       alert(result.message);
      //       history.replace("/login");
      //     } else {
      //       alert(result.message);
      //     }
      //   })
      //   .catch((error) => console.log("fetch error", error));
    } catch (error) {
      console.log("error", error.message);
      await alert(error.message);
    }
  };
  return (
    <div
      style={{
        padding: "5%",
        display: "flex",
        paddingTop: window.screen.width < 600 ? "20%" : null,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: window.screen.width < 600 ? "100vw" : "50%",
      }}
    >
      <div style={{ width: "100%", display: "flex", alignItems: "center", marginBottom: window.screen.width < 600 ? 20 : null, }}>
        <ArrowBackOutlinedIcon onClick={() => history.goBack()} style={{ marginRight: 25, marginTop: -10}} />
        <p
          style={{
            color: "#5E6C84",
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "22px",
          }}
        >
          Forgot Password
        </p>
      </div>
      <CustomInput
        labelText="Email Address"
        id="float"
        formControlProps={{
          fullWidth: true,
          onChange: (event) => handleChange("email", event.target.value),
        }}
      />
      <Button
        id="login-button"
        type="button"
        style={{
          backgroundColor: "#6FCF97",
          fontFamily: "Rubik",
          color: "#FAFAFA",
          fontStyle: "normal",
          fontWeight: 500,
          width: "100%",
          marginTop: window.screen.width < 600 ? 50 : 20,
        }}
        size="lg"
        onClick={() => handleReset()}
      >
        Reset Password
      </Button>
      <div style={{ marginTop: "4%" }}>
        Don't have an account?
        <Link to={"/signup"} id="signup-link">
          Sign Up
        </Link>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ResetPassword);
