import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import Profile from "./Profile";
import MobileMenu from "./MobileMenu";
import "../../Main.css";

function NavBar() {
  if (window.screen.width < 600) {
    return <MobileMenu />;
  }

  return (
    <div id="nav-div">
      <nav>
        <ScrollLink smooth={true} to={"about"} className="nav-link" href="">
          Who we are
        </ScrollLink>
        <ScrollLink smooth={true} to={"howto"} className="nav-link" href="">
          How to volunteer
        </ScrollLink>
        <ScrollLink
          smooth={true}
          to={"categories"}
          className="nav-link"
          href=""
        >
          Categories
        </ScrollLink>
        <Link to={"/faq"} className="nav-link">
          FAQ
        </Link>
      </nav>
      <div style={{  width: 250 }}>
        <Profile />
      </div>
    </div>
  );
}

export default NavBar;
