import React from "react";
import {Others as Header} from "../header";
import Applications from "./Applications";

export default function index() {
  return (
    <div>
      <Header />
      <Applications />
    </div>
  );
}
