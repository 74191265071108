import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    minWidth: window.screen.width > 600 ? 320 : "90vw",
    height: 300,
    marginBottom: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontWeight: 500,
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontSize: "20px",
    color: "#5E6C84"
  },
  pos: {
    marginBottom: 12,
    fontWeight: "normal",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontSize: "16px",
    color: "#5E6C84"
  },
}); 

export default function CategoriesCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" className={classes.title} component="h2" style={{marginBottom: 20, marginTop: '20px'}}>
         {props.title}
        </Typography>
        <Typography className={classes.pos} color="textSecondary" style={{marginBottom: 15}}>
          A brief description of what the category is all about. It should not
          be more than 50-80 words.
        </Typography>
        <Typography
          variant="body2"
          component="p"
          style={{
            display: "inline-block",
            borderBottom: "1px solid #6FCF97",
            fontWeight: "normal",
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontSize: "14px",
            color: "#5E6C84",
            paddingBottom: 3,
            lineHeight: "139%",
            letterSpacing: "0.1em"
          }}
        >
          25 opportunities
        </Typography>
      </CardContent>
      <Link
        to={`/categories/${props.id}`}
        style={{
          backgroundColor: "#6FCF97",
          width: "100%",
          height: 50,
          paddingTop: 15,
          color: "white",
          paddingLeft: 30,
          fontWeight: "bold",
        }}
      >
        Browse Catergory
      </Link>
    </Card>
  );
}
