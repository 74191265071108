import React from "react";
import Profile from "./Profile";
import "../../Main.css";

function NavBar() {
  return (
    <div style={{ height: 30, top: 6 }}>
      <Profile />
    </div>
  );
}

export default NavBar;
