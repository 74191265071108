import React from "react";
import { Auth as Header } from "../header";
import ResetPassword from "./ResetPassword";
import "../Main.css";

export default function index() {
  if (window.screen.width < 600) {
    return (
      <div
        style={{
          backgroundColor: "#FAFAFA",
          height: "100%",
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <Header />
        <ResetPassword />
      </div>
    );
  }
  return (
    <div style={{ backgroundColor: "white", height: "100vh" }}>
      <Header />
      <div className="auth-component">
        <img src={require("assets/img/login-image.png")} alt="login" />
        <div className="divider"></div>
        <ResetPassword />
      </div>
    </div>
  );
}
