import React from "react";
import { Auth as Header } from "../header";
import { Others as HeaderOthers } from "../header";
import Body from "./body";

export default function index() {
  return (
    <div
      style={{ backgroundColor: "#FAFAFA", height: "100%", minHeight: "100vh" }}
    >
      {localStorage.getItem("isAuthenticated") ? <HeaderOthers /> : <Header />}
      <Body />
    </div>
  );
}
