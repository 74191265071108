import React from "react";
import { Default as Header } from "../header";
import HeaderLanding from "../header/header-landing";
import Footer from "../footer";
import About from "./About";
import HowTo from "./HowTo";
import Categories from "./Categories";
import Summary from "./Summary";
import Button from "components/CustomButtons/Button";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import "../Main.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import Partners from "./Partners";

const useStyles = makeStyles(styles);

export default function () {
  const classes = useStyles();

  return (
    <div xs={12} lg={12} xl={12}>
      <div
        style={{
          backgroundImage: `url(${require("assets/img/background-image.png")})`,
          width: "100vw",
          height: window.screen.width > 600 ? "95vh" : "85vh",
          top: 1,
        }}
        id="background-image"
      >
        {localStorage.getItem("isAuthenticated") ? (
          <HeaderLanding />
        ) : (
            <Header />
          )}
        <div style={{ marginLeft: window.screen.width > 600 ? "8%" : "1%", paddingTop: "7vh", width: "90vw" }} className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1
                style={{
                  color: "#344563",
                  fontWeight: "bold",
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontSize: "36px",
                  marginTop: window.screen.width > 600 ? "40%" : "10vh"
                }}
                className={classes.title}
              >
                Helping others is the way we help ourselves.
              </h1>
              <h4
                style={{
                  fontWeight: "normal",
                  color: "#5E6C84",
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontSize: "20px",
                  lineHeight: "28px"
                }}
              >
                This is the time we need to stand up and help out in anyway we can, 
                no matter how little it seems. This is the only way we can collectively beat this.
              </h4>
              <Link to={"/login"}>
                <Button type="button" style={{ backgroundColor: "#6FCF97", marginTop: window.screen.width > 600 ? "6vh" : "2vh" }}>
                  View opportunities
                </Button>
              </Link>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <div xs={12} className="about">
        <About />
      </div>
      <div className="howto">
        <HowTo />
      </div>
      <div className="categories">
        <Categories />
      </div>
      <Partners />
      <Summary />
      <Footer />
    </div>
  );
}
