import React from "react";
import Button from "components/CustomButtons/Button";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import "../../Main.css";

function NavBar() {
  if (window.screen.width < 600) {
    return null;
  }

  return (
    <div>
      <Link to={"/signup"} id="signup-link">
        Sign Up
      </Link>
      <Link to={"/login"}>
        <Button type="button" style={{ backgroundColor: "#6FCF97" }}>
          Sign In
        </Button>
      </Link>
    </div>
  );
}

export default NavBar;
