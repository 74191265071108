import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
link: {
    color: "#5E6C84",
    fontWeight: "normal",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontSize: "14px"
}
}));

function FooterNav() {
    const classes = useStyles();
  return (
    <div id="footer-nav-link">
      <Link className={classes.link} to={"/faq"}>
      FAQ
      </Link>
      <Link className={classes.link} to={"/contact"}>
      Contact Us
      </Link>
      <Link className={classes.link} to={"/terms"}>
      Terms of Use
      </Link>
      <Link className={classes.link} to={"/policy"}>
      Privacy Policy
      </Link>
    </div>
  );
}

export default FooterNav;
