import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);
const useStylesCustom = makeStyles(theme => ({
  list: {
    padding: "4px 8px"
  },
  anchor: {
    padding: "7.5px"
  }
}))

function Social() {
  const classes = useStyles();
  const customClass = useStylesCustom();
  return (
    <div style={{display: "flex", justifyContent: "center",}}>
        <ListItem className={customClass.list} >
          <Tooltip
            id="instagram-facebook"
            title="Follow us on facebook"
            placement={window.innerWidth > 959 ? "top" : "top"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href="https://www.facebook.com/"
              target="_blank"
              className={`${classes.navLink} ${customClass.anchor}`}
            >
              <i className={classes.socialIcons + " fab fa-facebook"} />
            </Button>
          </Tooltip>
        </ListItem>
        <ListItem className={customClass.list} >
          <Tooltip
            id="instagram-twitter"
            title="Follow us on twitter"
            placement={window.innerWidth > 959 ? "top" : "top"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              href="https://twitter.com/"
              target="_blank"
              color="transparent"
              className={`${classes.navLink} ${customClass.anchor}`}
            >
              <i className={classes.socialIcons + " fab fa-twitter"} />
            </Button>
          </Tooltip>
        </ListItem>
        <ListItem className={customClass.list} >
          <Tooltip
            id="instagram-tooltip"
            title="Follow us on instagram"
            placement={window.innerWidth > 959 ? "top" : "top"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href="https://www.instagram.com/"
              target="_blank"
              className={`${classes.navLink} ${customClass.anchor}`}
            >
              <i className={classes.socialIcons + " fab fa-instagram"} />
            </Button>
          </Tooltip>
        </ListItem>
    </div>
  );
}

export default Social;
