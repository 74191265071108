import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import { Link, withRouter } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import Divider from "@material-ui/core/Divider";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#E5E5E5"
  },
  typography: {
    padding: theme.spacing(2),
    width: "98vw",
  },
  icon: {
    height: 40,
    width: 80,
    color: "#6FCF97",
  },
  link: {
    width: "45%",
    textAlign: "center",
  },
  nav: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
}));

function MobileMenu(props) {
  const { history } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const classes = useStyles();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleLogout = () => {
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("data");
    history.push("/login");
  };

  return (
    <div className={classes.root}>
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper onClick={handleClick("bottom-end")}>
              <div className={classes.typography}>
                <nav className={classes.nav}>
                  <ScrollLink
                    smooth={true}
                    to={"about"}
                    className="nav-link"
                    href=""
                    onClick={handleClick("bottom-end")}
                  >
                    Who we are
                  </ScrollLink>
                  <ScrollLink
                    smooth={true}
                    to={"howto"}
                    className="nav-link"
                    href=""
                    onClick={handleClick("bottom-end")}
                  >
                    How to volunteer
                  </ScrollLink>
                  <ScrollLink
                    smooth={true}
                    to={"categories"}
                    className="nav-link"
                    href=""
                    onClick={handleClick("bottom-end")}
                  >
                    Categories
                  </ScrollLink>
                  <Link
                    to={"/faq"}
                    className={`${classes.link} nav-link`}
                  >
                    FAQ
                  </Link>
                </nav>
                <Divider
                  style={{
                    marginTop: "5vw",
                  }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    margin: 10,
                  }}
                >
                  <Link
                    to={"/edit-profile"}
                    className={classes.link}
                    id="signup-link"
                  >
                    Edit Profile
                  </Link>
                  <Link to={""}
                    className={classes.link}
                    onClick={handleLogout}
                    id="signup-link">
                      Log Out
                  </Link>
                </div>
                <Divider
                  style={{
                    marginBottom: "10vw",
                  }}
                />
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
      <div
        style={{
          backgroundColor: "transparent",
          zIndex: 1,
          position: "absolute",
          right: "2%",
          top: ".7%",
        }}
        onClick={handleClick("bottom-end")}
      >
        {open ? (
          <CloseOutlinedIcon className={classes.icon} />
        ) : (
          <MenuOutlinedIcon className={classes.icon} />
        )}
      </div>
    </div>
  );
}

MobileMenu.propTypes = {
  history: PropTypes.object,
};

export default withRouter(MobileMenu)