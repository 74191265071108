import "date-fns";
import React from "react";
import Button from "components/CustomButtons/Button";
import ProfileSelect from "../Utils/CustomSelect";
import ProfileSelectCountry from "../Utils/CustomSelectCountry";
import ProfileInput from "../Utils/CustomInput";
import ProfileInputTag from "../Utils/CustomInputTag";
import data from "../../data";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";


import { makeStyles } from "@material-ui/core/styles";

import jsonData from "../../data.json";

const useStyles = makeStyles((theme) => ({
  error: {
    color: "red",
    marginTop: -11,
    paddingLeft: 10
  }
}));

function ProfileForm(props) {

  const classes = useStyles();
  const [state, setState] = React.useState([
    {
      name: "",
    },
  ]);

  const [error, setError] = React.useState({});

  const handleDateChange = (date) => props.handleDateChange(date);

  const handleSelectChange = (event) => props.handleSelectChange(event);

  const handleSelectCountryChange = (event) => {
    const name = event.target.value;
    let result = jsonData.find((data) => data.state.name === name);
    if (name) {
      setState(result.state.locals);
    } else {
      setState([
        {
          name: "",
        },
      ]);
    }
    return props.handleSelectCountryChange(name);
  };

  const getChange = (name, value) => props.handleChange(name, value);

  const handlePhoneNumberValidation = (name, value) => {
    if (value.match(/^[0]\d{10}$/)) {
      setError({phone_number: null});
    } else {
      setError({phone_number: "phone number error"});
    }
    getChange(name, value);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ marginRight: 15, width: "45%" }}>
          <ProfileInput
            label={"First Name"}
            id={"first_name"}
            value={props.data.first_name}
            handleChange={getChange}
            hasValue={true}
          />
          {console.log("check check", JSON.stringify(props))}
        </div>
        <div style={{ width: "50%" }}>
          <ProfileInput
            label={"Last Name"}
            id={"surname"}
            value={props.data.surname}
            handleChange={getChange}
            hasValue={true}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ProfileSelect
          title={"Gender"}
          handleChange={handleSelectChange}
          data={data.gender}
          value={props.data.gender}
          id={"gender"}
          hasValue={true}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableFuture
            variant="inline"
            openTo="year"
            format="MM/dd/yyyy"
            margin="normal"
            views={["year", "month", "date"]}
            placeholder="dd/mm/yyyy"
            // label="Date of Birth"
            value={props.selectedDate || new Date(props.data.date_of_birth)}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            style={{ width: "50%", marginTop: "6%" }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <ProfileInput
        label={"Email Address"}
        id={"email"}
        value={props.data.email}
        handleChange={getChange}
        hasValue={true}
      />
      <ProfileInput
        error={error.phone_number && true}
        label={"Phone Number"}
        id={"phone_number"}
        value={props.data.phone_number}
        handleChange={handlePhoneNumberValidation}
        hasValue={true}
      />
      {error.phone_number && <p className={classes.error}>Phone number must start with "0" and must be 10 digits long</p>}
      <ProfileInput
        label={"Address"}
        id={"address"}
        value={props.data.address}
        handleChange={getChange}
        hasValue={true}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 5,
          marginBottom: 30,
        }}
      >
        {console.log(props.data)}
        <ProfileSelectCountry
          title={"State"}
          handleChange={handleSelectCountryChange}
          data={jsonData}
          value={props.data.state}
          id={"state"}
          hasValue={true}
        />
        <ProfileSelect
          title={"LGA"}
          handleChange={handleSelectChange}
          data={state}
          value={props.data.gender}
          id={"lga"}
          hasValue={true}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <div style={{ marginRight: 15, width: "45%", paddingTop: 20,  }}>
          <ProfileInputTag
            label={"Spoken Languages"}
            id={"spoken_languages"}
            value={props.data.spoken_languages}
            handleChange={getChange}
            hasValue={true}
          />
        </div>
        <ProfileSelect
          title={"Highest Qualification"}
          handleChange={handleSelectChange}
          data={data.qualifications}
          value={props.data.highest_education}
          id={"highest_education"}
          hasValue={true}
        />
      </div>
      <Button
        id="login-button"
        onClick={() => props.handleProfile()}
        type="button"
        style={{ backgroundColor: "#6FCF97", marginTop: 15, fontSize: "14px" }}
        size="lg"
      >
        Save
      </Button>
    </div>
  );
}

export default ProfileForm;
