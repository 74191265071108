import React from "react";
import Button from "components/CustomButtons/Button";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

function Summary() {
  return (
    <div
      style={{
        height: 400,
        width: "100vw",
        backgroundColor: "#FFEDCF",
        // opacity: 0.6,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h3
        style={{
          color: "#5E6C84",
          fontWeight: "normal",
          marginBottom: 50,
          fontSize: "24px",
          fontFamily: "Rubik",
          fontStyle: "normal",
        }}
      >
        It's time to make a difference
      </h3>
      <p
        style={{
          fontSize: "16px",
          fontWeight: "normal",
          width: "50%",
          textAlign: "center",
          marginBottom: 40,
          fontFamily: "Rubik",
          fontStyle: "normal",
        }}
      >
        The world is depending on you to make it better, no matter how little or
        large. <br />
        You can volunteer to make it better by starting now
      </p>
      <Link to={"/login"}>
        <Button
          type="button"
          style={{
            backgroundColor: "#6FCF97",
            fontFamily: "Rubik",
            color: "#FAFAFA",
            fontStyle: "normal",
            fontWeight: 500,
          }}
        >
          View opportunities
        </Button>
      </Link>
    </div>
  );
}

export default Summary;
