import React from "react";

function HowToCard(props) {
  return (
    <div
      style={{
        width: 250,
        height: 150,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          border: "0.471714px solid #6FCF97",
          borderRadius: "50%",
          backgroundColor: "white",
          width: 30,
          height: 30,
          padding: 2,
        }}
      >
        <p
          style={{
            border: "1px solid #6FCF97",
            textAlign: "center",
            fontSize: 16,
            width: 25,
            height: 25,
            borderRadius: "50%",
            backgroundColor: "#FFEDCF",
          }}
        >
          {props.id}
        </p>
      </div>
      <p
        style={{
          width: 250,
          height: "auto",
          fontSize: "16px",
          textAlign: "center",
          fontWeight: "normal",
          fontFamily: "Rubik",
          fontStyle: "normal",
        }}
      >
        {props.message}
      </p>
    </div>
  );
}

export default HowToCard;
