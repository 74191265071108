import React from "react";
import Navbar from "./NavBar";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import "../../Main.css";

export default function () {

  window.onscroll = () => {
    if (window.scrollY < 7) {
      document.getElementById("header").style.backgroundColor = "transparent";
    } else {
      document.getElementById("header").style.backgroundColor = "white";
    }
  };

  return (
    <div style={{}} id="header">
      <Link to={"/"}>
        <img src={require("assets/img/logo1.png")} alt="logo" />
      </Link>
      <Navbar />
    </div>
  );
}
