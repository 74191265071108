import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
// @material-ui/icons
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
// react components for routing our app without refresh
import { 
  // Link, 
  withRouter } from "react-router-dom";
// import baseURL from "../../BaseUrl";

const useStyles = makeStyles((theme) => ({
  h: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    color: "#5E6C84"
  },
  p: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    color: "#5E6C84"
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "3vw",
    paddingRight: "2vw"
  }
}));

const Login = (props) => {
  const classes = useStyles();
  const { history } = props;

  const [state, setState] = React.useState({ email: "", password: "" });
  const handleChange = async (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };
  const handleSendMessage = async () => {
    try {
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div
      style={{
        padding: window.screen.width > 600 ? "10%" : "5%",
        paddingTop: window.screen.width < 600 ? "25%" : "8%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: window.screen.width < 600 ? "100vw" : "50%",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <ArrowBackOutlinedIcon
          onClick={() => history.goBack()}
          style={{ marginRight: 25, marginTop: -10 }}
        />
        <p
          style={{
            color: "#5E6C84",
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: window.screen.width > 600 ? "22px" : "19px",
          }}
        >
          Contact Us
        </p>
      </div>
      {window.screen.width < 600 && (
        <div>
          <h3 className={classes.h}>Address</h3>
          <p className={classes.p}>
            222, ifelodun area, yvyvhvvk ogun state, Nigeria
          </p>
          <h3 className={classes.h}>Phone Number</h3>
          <p className={classes.p}>08012345678</p>
          <h3 className={classes.h}>Email</h3>
          <p className={classes.p}>ogunstatevolunteer@gmail.com</p>
        </div>
      )}
      <p
        style={{
          width: "100%",
          marginTop: "8%",
          color: "#5E6C84",
          fontFamily: "Rubik",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "18px",
        }}
      >
        Send us a message
      </p>
      <CustomInput
        error={false}
        labelText="Name"
        id="float"
        formControlProps={{
          fullWidth: true,
          onChange: (event) => handleChange("name", event.target.value),
        }}
      />
      <CustomInput
        labelText="Email Address"
        id="float"
        formControlProps={{
          fullWidth: true,
          onChange: (event) => handleChange("email", event.target.value),
        }}
      />
      <br />
      <br />
      <br />
      <CustomInput
        labelText="message"
        id="material"
        formControlProps={{
          fullWidth: true,
          onChange: (event) => handleChange("message", event.target.value),
        }}
      />
      <Button
        id="login-button"
        type="button"
        style={{
          backgroundColor: "#6FCF97",
          fontFamily: "Rubik",
          color: "#FAFAFA",
          fontStyle: "normal",
          fontWeight: 500,
          marginTop: 30,
        }}
        size="lg"
        onClick={() => handleSendMessage()}
      >
        Send Message
      </Button>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Login);
