import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import data from "../../data";
import Card from "../Utils/CustomCard";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // padding: 30,
    marginTop: 30
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  p: {
    width: "100%",
    marginBottom: window.screen.width < 600 ? -15 : null,
    marginTop: window.screen.width < 600 ? -5 : null,
    // paddingLeft: window.screen.width < 600 ? "10%" : 60,
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    color: "#5E6C84",
    textDecoration: window.screen.width < 600 ? "underline" : null,
    textDecorationColor: window.screen.width < 600 ? "#6FCF97" : null
  },
}));

function Categories() {
  const classes = useStyles();
  return (
    <div
      style={{
        width: "100vw",
        backgroundColor: "#FAFAFA",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "7vh",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          padding: window.screen.width > 600 ? "3%" : null,
          paddingRight: window.screen.width > 600 ? "5%" : null,
          paddingLeft: window.screen.width > 600 ? "5%" : null,
        }}
      >
        <Header />
        <h5 className={classes.p}>Approved</h5>
        <Grid
          container
          className={classes.root}
          xs={1}
          sm={12}
          md={12}

        >
          {data.applications.approved.map((info, index, arr) =>
            arr[0] ? (
              index < 6 ? (
                <Grid
                  key={info.id}
                  item
                  style={{ display: "flex", justifyContent: "center" }}
                  xs={1}
                  sm={6}
                  md={6}
                  lg={4}
                >
                  <Card id={info.id} icon="check" />
                </Grid>
              ) : null
            ) : null
          )}
        </Grid>
        <h5 className={classes.p}>Declined</h5>
        <Grid
          container
          className={classes.root}
          xs={1}
          sm={12}
          md={12}
        >
          {data.applications.declined.map((info, index, arr) =>
            arr[0] ? (
              index < 6 ? (
                <Grid
                  key={info.id}
                  item
                  style={{ display: "flex", justifyContent: "center" }}
                  xs={1}
                  sm={6}
                  md={6}
                  lg={4}
                >
                  <Card id={info.id} icon="cancel" />
                </Grid>
              ) : null
            ) : null
          )}
        </Grid>
        <h5 className={classes.p}>Pending </h5>
        <Grid
          container
          className={classes.root}
          xs={1}
          sm={12}
          md={12}
        >
          {data.applications.pending.map((info, index, arr) =>
            arr[0] ? (
              index < 6 ? (
                <Grid
                  key={info.id}
                  item
                  style={{ display: "flex", justifyContent: "center" }}
                  xs={1}
                  sm={6}
                  md={6}
                  lg={4}
                >
                  <Card id={info.id} />
                </Grid>
              ) : null
            ) : null
          )}
        </Grid>
      </div>
    </div>

  );
}

export default Categories;
