import React from "react";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleSharpIcon from "@material-ui/icons/CheckCircleSharp";
import CancelSharpIcon from "@material-ui/icons/CancelSharp";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "100vw",
    height: "100vh",
    position: "absolute",
    backgroundColor: "rgba(196, 196, 196, 0.6);",
    zIndex: 2,
    marginBottom: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontWeight: "normal",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontSize: "22px",
    color: "#5E6C84",
    textAlign: "center",
    marginBottom: "5vh",
    marginTop: "1vh",
  },
  button: {
    fontWeight: "normal",
    fontFamily: "Rubik",
    fontStyle: "normal",
    backgroundColor: "#6FCF97",
    width: "50%",
    color: "#FAFAFA",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  div: {
    width: "326px",
    height: "279.54px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 30,
  },
  iconCheck: {
    marginBottom: -50,
    zIndex: 3,
    width: "93px",
    height: "93px",
    color: "#6FCF97",
  },
  iconCancel: {
    marginBottom: -50,
    zIndex: 3,
    width: "93px",
    height: "93px",
    color: "#E43433",
  },
});

function CustomAlert(props) {
    const {history} = props;
  const classes = useStyles();
  const [
    open, 
    setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    handleClickOpen();
    console.log(props);
  });

  const handleButtonFunction = () => {
      if (props.type === "success"){
          history.replace(`/${props.destination}`);
      }
      props.callbackModal();
  };

  return (
    <div open={open} className={classes.root}>
      <div
        className={classes.iconCheck}
        style={{
          backgroundColor: "white",
          zIndex: 2,
          borderRadius: "50%",
          marginBottom: -90,
          width: "75px",
          height: "75px",
        }}
      ></div>
      {props.type === "success" ? (
        <CheckCircleSharpIcon className={classes.iconCheck} />
      ) : (
        <CancelSharpIcon className={classes.iconCancel} />
      )}
      <div className={classes.div}>
        <p className={classes.title}>{props.message}</p>
        <Button
          className={classes.button}
          onClick={handleButtonFunction}
          color="primary"
        >
          {props.type === "success" ? "Continue" : "close"}
        </Button>
      </div>
    </div>
  );
}

CustomAlert.propTypes = {
    history: PropTypes.object,
  };
  
  export default withRouter(CustomAlert);