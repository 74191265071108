import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.8.0";

// pages for this product
import LandingPage from "views/LandingPage";
import LoginPage from "views/LoginPage";
import SignupPage from "views/SignupPage";
import CategoriesPage from "views/CategoriesPage";
import CategoryPage from "views/CategoriesPage/CategoryPage";
import ApplicationsPage from "views/ApplicationsPage";
import EditProfilePage from "views/ProfilePage";
import ResetPasswordPage from "views/ForgotPasswordPage";
import ContactPage from "views/ContactUsPage";
import PrivacyPage from "views/PrivacyPage";
import TermsPage from "views/TermsOfUsePage";
import FaqPage from "views/FAQPage";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route path="/faq" component={FaqPage} />
      <Route path="/terms" component={TermsPage} />
      <Route path="/policy" component={PrivacyPage} />
      <Route path="/contact" component={ContactPage} />
      <Route path="/edit-profile" component={EditProfilePage} />
      <Route path="/applications" component={ApplicationsPage} />
      <Route path={`/categories/:category`} component={CategoryPage} />
      <Route path="/categories" component={CategoriesPage} />
      <Route path="/reset-password" component={ResetPasswordPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/signup" component={SignupPage} />
      <Route path="/not-found" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
