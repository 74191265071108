import React from "react";
import { Link } from "react-router-dom";

function Header(props) {
  if (window.screen.width < 600) {
    return (
      <div
        style={{
          height: 100,
          width: "90vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "space-between",
          marginBottom: 10
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "flex-end",
            marginTop: 20,
            marginBottom: 20
          }}
        >
          <Link
            to={"/categories"}
            style={{
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "14px",
              color: "#5E6C84",
            }}
          >
            Find Opportunities
          </Link>
          <div
            style={{ backgroundColor: "#6FCF97", width: 120, height: "1px" }}
          ></div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              marginLeft: window.screen.width < 600 ? "9%" : null,
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "22px",
              color: "#5E6C84",
            }}
          >
            Applications
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        height: 100,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <p
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "22px",
            color: "#5E6C84",
          }}
        >
          Applications
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Link
          to={"/categories"}
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            color: "#5E6C84",
          }}
        >
          Find Opportunities
        </Link>
        <div
          style={{ backgroundColor: "#6FCF97", width: 120, height: "1px" }}
        ></div>
      </div>
    </div>
  );
}

export default Header;
