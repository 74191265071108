import React from "react";
import CustomInput from "components/CustomInput/CustomInput";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: "1px solid green",
    overflow: "hidden",
    backgroundColor: "#fff",
    color: "rgba(94, 108, 132, 0.5)",
    "&:hover": {
      borderBottom: "1px solid red",
    },
    "&$focused": {
      borderBottom: "1px solid green",
    }
  },
  focused: {}
}));

function SignupInput(props) {
  const classes = useStyles();
  return (
    <CustomInput
      labelText={props.hasValue ? null : props.label}
      id={props.id}
      error={props.error}
      className={classes.root}
      formControlProps={{
        fullWidth: true,
        onChange: (event) => props.handleChange(props.id, event.target.value),
      }}
      
      inputProps={{
        fontSize: "16px",
        fontWeight: "normal",
        fontFamily: "Rubik",
        fontStyle: "normal",
        value: props.value,
        placeholder: props.hasValue ? props.label : null
      }}
      
      // labelProps={{
      //   backgroundColor: "#fff",
      //   borderBottom: "1px solid red",
      //   fontSize: "16px",
      //   fontWeight: "normal",
      //   fontFamily: "Rubik",
      //   fontStyle: "normal",
      //   color: "rgba(94, 108, 132, 0.5)",
      // }}
    />
  );
}

export default SignupInput;
