import React from "react";
import Navbar from "./NavBar";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import "views/Main.css";

export default function() {
  return (
    <div style={{ backgroundColor: "white", justifyContent: "space-between", padding: "12px 10%"}} id="header">
      <Link to={"/"}>
        <img src={require("assets/img/logo1.png")} alt="logo" />
      </Link>
      <Navbar />
    </div>
  );
}

