import React from "react";
import { Auth as Header } from "../header";
import { Others as HeaderOthers } from "../header";
import { makeStyles } from "@material-ui/core/styles";
import Contact from "./Contact";
import "../Main.css";

const useStyles = makeStyles((theme) => ({
  h: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
  },
  p: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "6vw",
    paddingRight: "2vw",
  },
}));

export default function Index() {
  const classes = useStyles();
  if (window.screen.width < 600) {
    return (
      <div
        style={{ backgroundColor: "#FAFAFA", height: "100%", width: "100vw" }}
      >
        {localStorage.getItem("isAuthenticated") ? (
          <HeaderOthers />
        ) : (
          <Header />
        )}
        <Contact />
      </div>
    );
  }
  return (
    <div style={{ backgroundColor: "#FAFAFA", height: "100vh" }}>
      {localStorage.getItem("isAuthenticated") ? <HeaderOthers /> : <Header />}
      <div className="auth-component" style={{ paddingTop: "5%" }}>
        <div
          style={{
            flexDirection: "column",
            display: "flex",
          }}
        >
          <img
            src={require("assets/img/contact-us.png")}
            style={{ height: "40vh", padding: "30px", paddingLeft: "5vw" }}
            alt="login"
          />
          <div className={classes.div}>
            <div>
              <h3 className={classes.h}>Address</h3>
              <p className={classes.p}>
                222, ifelodun area, yvyvhvvk ogun state, Nigeria
              </p>
              <h3 className={classes.h}>Phone Number</h3>
              <p className={classes.p}>08012345678</p>
            </div>
            <div>
              <h3 className={classes.h}>Email</h3>
              <p className={classes.p}>ogunstatevolunteer@gmail.com</p>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <Contact />
      </div>
    </div>
  );
}
