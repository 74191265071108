import React from "react";
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
// react components for routing our app without refresh
import { Link, withRouter } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    minWidth: window.screen.width > 600 ? 320 : "90vw",
    width: 375,
    height: 300,
    marginBottom: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  title: {
    fontWeight: 500,
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontSize: "20px",
    color: "#5E6C84"
  },
  pos: {
    marginBottom: 12,
    fontWeight: "normal",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontSize: "16px",
    color: "#5E6C84"
  },
});

function CategoriesCard(props) {
  const classes = useStyles();
  const {match} = props; 

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography variant="h5" className={classes.title} component="h2" style={{marginBottom: 20, marginTop: 20}}>
          {props.title}
        </Typography>
        <Typography className={classes.pos} color="textSecondary" style={{marginBottom: 15}}>
          A brief description of what the category is all about. It should not
          be more than 50-80 words.
        </Typography>
        <Typography
          variant="body2"
          component="p"
          color="textSecondary"
          style={{
            fontWeight: "normal",
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontSize: "14px",
            color: "#5E6C84"
          }}
        >
          6hrs/day
        </Typography>
      </CardContent>
      <Link
        to={`${match.url}/${props.id}`}
        style={{
          backgroundColor: "#6FCF97",
          width: "100%",
          height: 50,
          padding: 13,
          color: "white",
          paddingLeft: 30,
          fontWeight: "bold",
        }}
      >
        Check Requirements
      </Link>
    </Card>
  );
}

CategoriesCard.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

export default withRouter(CategoriesCard)