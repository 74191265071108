import React from "react";

function About() {
  return (
    <div
      style={{
        height: "auto",
        width: "100vw",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: 40,
        paddingTop: 20,
      }}
    >
      <h3
        style={{
          margin: '30px 0',
          color: "#6FCF97",
          fontWeight: "bold",
          fontFamily: "Rubik",
          fontStyle: "normal",
          fontSize: "16px",
          letterSpacing: "0.1em"
        }}
      >
        WHO WE ARE
      </h3>
      <p
        style={{
          width: "58%",
          minWidth: 325,
          textAlign: "center",
          fontSize: "20px",
          // height: "auto",
          fontWeight: "normal",
          fontFamily: "Rubik",
          fontStyle: "normal",
          lineHeight: '28px'
        }}
      >
        The Ogun State Volunteer Portal is an initiative of The Ogun State
        Government supported by the Honeywell Group Foundation. Ogun State is a
        state in southwestern Nigeria. Created in 1976, it borders Lagos State
        to the south, Oyo and Osun states to the north, Ondo to the east, and
        the Republic of Benin to the west. Abeokuta is the capital and largest
        city in the state.
        <br /> <br />
        The Volunteer Platform would be the gateway to all
        volunteer opportunities in Ogun State across all forms of activities.
      </p>
    </div>
  );
}

export default About;
