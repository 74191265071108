import React from "react";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomAlert from "../Utils/CustomAlert";
// material-ui components
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
// react components for routing our app without refresh
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import baseURL from "../../BaseUrl";
axios.defaults.baseURL = baseURL;

const Login = (props) => {
  // const { history } = props;

  const [state, setState] = React.useState({ email: "", password: "" });
  const [loginState, setLoginState] = React.useState({});

  const handleChange = async (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const callbackModal = () => {
    setLoginState({ showModal: false });
  };
 
  if (loginState.showModal) {
    return (
      <CustomAlert
        destination={loginState.destination}
        type={loginState.type}
        message={loginState.message}
        callbackModal={callbackModal}
      />
    );
  }

  const handleSignIn = async () => {
    try {
      const { email, password } = state;
      console.log("state values", email, password);

      if (!email && !password) {
        throw Error("you must enter your email and password to continue");
      }
      if (!email) {
        throw Error("please enter email address");
      } else if (!password) {
        throw Error("please enter your password");
      }
      fetch(`${baseURL}/user-sign-in`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      })
        .then((response) => response.json())
        .then(async (result) => {
          console.log("result", result);
          if (result.status) {
            localStorage.setItem("isAuthenticated", "true");
            localStorage.setItem("data", JSON.stringify(result.data));
            await setLoginState({
              ...loginState,
              showModal: true,
              destination: "categories",
              type: "success",
              message: result.message,
            });
          } else {
            await setLoginState({
              ...loginState,
              showModal: true,
              type: "error",
              message: result.message,
            });
          }
        })
        .catch((error) => console.log("fetch error", error));
    } catch (error) {
      await setLoginState({
        ...loginState,
        showModal: true,
        type: "error",
        message: error.message,
      });
    }
  };
  return (
    <div
      style={{
        padding: "10%",
        marginTop: window.screen.width < 600 ? "10%" : null,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: window.screen.width < 600 ? "100vw" : "50%",
      }}
    >
      <CustomInput
        labelText="Email Address"
        id="float"
        formControlProps={{
          fullWidth: true,
          onChange: (event) => handleChange("email", event.target.value),
        }}
      />
      <CustomInput
        labelText="Password"
        id="material"
        formControlProps={{
          fullWidth: true,
          onChange: (event) => handleChange("password", event.target.value),
        }}
        inputProps={{
          type: "password",
          endAdornment: (
            <InputAdornment position="end">
              <VisibilityOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
      <div style={{ width: "100%" }}>
        <Link
          to={"/reset-password"}
          style={{ fontStyle: "italic", color: "slategrey" }}
        >
          Forgot Password?
        </Link>
      </div>
      <Button
        id="login-button"
        type="button"
        style={{
          backgroundColor: "#6FCF97",
          fontFamily: "Rubik",
          color: "#FAFAFA",
          fontStyle: "normal",
          fontWeight: 500,
          marginTop: 30,
        }}
        size="lg"
        onClick={() => handleSignIn()}
      >
        Start Volunteering
      </Button>
      <div style={{ marginTop: "3%" }}>
        Don't have an account?
        <Link to={"/signup"} id="signup-link">
          Sign Up
        </Link>
      </div>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Login);
