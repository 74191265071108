import React from "react";
import {Auth as Header} from "../header";
import Signup from "./Signup";
import "../Main.css";

export default function Index() {

  if (window.screen.width < 600) {
    return (
      <div style={{ backgroundColor: "#FAFAFA", height: "100%", minHeight: "100vh", width: "100vw" }}>
        <Header />
        <Signup />
      </div>
    );
  }
  return (
    <div style={{backgroundColor: "#FAFAFA", height: "100%"}}>
      <Header />
      <div className="auth-component">
        <img src={require("assets/img/login-image.png")} alt="login" />
        <div className="divider"></div>
        <Signup />
      </div>
    </div>
  );
}
