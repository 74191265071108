import React from "react";
import { Link } from "react-router-dom";
import CategoriesSelect from "./CategoriesSelect";

function CategoriesHeader(props) {
  if (window.screen.width < 600) {
    return (
      <div 
      style={{
        height: 100,
        width: "90vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
            alignItems: "flex-end",
            marginBottom: 20,
          }}
        >
          <Link
            to={"/applications"}
            style={{
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "14px",
              color: "#5E6C84",
            }}
          >
            My Applications
          </Link>
          <div
            style={{ backgroundColor: "#6FCF97", width: 107, height: "1px" }}
          ></div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "22px",
              color: "#5E6C84",
            }}
          >
            Categories:
          </p>
          <CategoriesSelect />
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        height: 100,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <p
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "22px",
            color: "#5E6C84",
          }}
        >
          Categories:
        </p>
        <CategoriesSelect />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Link
          to={"/applications"}
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            color: "#5E6C84",
          }}
        >
          My Applications
        </Link>
        <div
          style={{ backgroundColor: "#6FCF97", width: 107, height: "1px" }}
        ></div>
      </div>
    </div>
  );
}

export default CategoriesHeader;
