import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
    marginTop: -5,
    minWidth: 120,
    width: 300,
  },
  selectEmpty: {
    padding: theme.spacing(1),
  },
}));

export default function CategoriesSelect() {
  const classes = useStyles();
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Select
      disableUnderline={true}
      id="demo-simple-select-autowidth"
      displayEmpty
      className={classes.formControl}
      inputProps={{ "aria-label": "Without label" }}
      value={age}
      onChange={handleChange}
      label="Categories"
      autoWidth
      style={{

        border: "1px solid #CCCCCC",
        boxSizing: "border-box",
        borderRadius: "5px",
        width: 200,
        height: 50
      }}
    >
      <MenuItem value="">All Categories</MenuItem>
      <MenuItem value={10}>Category 1</MenuItem>
      <MenuItem value={20}>Category 2</MenuItem>
      <MenuItem value={30}>Category 3</MenuItem>
    </Select>
  );
}
