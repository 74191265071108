import React from "react";
import PropTypes from "prop-types";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import { withRouter } from "react-router-dom";

function ProfileHeader(props) {
    const { history } = props;
    const handleBack = () => {
        history.goBack()
    }
  return (
    <div
      style={{
        display: "flex",
        minWidth: 350,
        paddingLeft: window.screen.width < 600 ? "5%" : 90,
        width: "100%",
        alignItems: "center",
        marginTop: 30,
        paddingTop: "7vh",
      }}
    >
      <ArrowBackOutlinedIcon onClick={handleBack} />
      <p
        style={{
          fontFamily: "Rubik",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: window.screen.width < 600 ? "18px" : "24px",
          color: "#5E6C84",
          marginLeft: window.screen.width < 600 ? "5%" : 35,
          marginTop: 10,
          textAlign: "center",
        }}
      >
        Edit Profile
      </p>
    </div>
  );
}

ProfileHeader.propTypes = {
    history: PropTypes.object,
  };
  

export default withRouter(ProfileHeader);
