import React from "react";
import {Others as Header} from "../header";
import Categories from "./Categories";

export default function index() {
  return (
    <div>
      <Header />
      <Categories />
    </div>
  );
}
