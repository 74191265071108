import React from "react";
import { Others as Header } from "../header";
import Profile from "./Profile";
import Profileheader from "./ProfileHeader";
import "../Main.css";

export default function Index() {

  const data = JSON.parse(localStorage.getItem("data"));

  if (window.screen.width < 600) {
    return (
      <div
        style={{
          backgroundColor: "#FAFAFA",
          height: "100%",
          minHeight: "100vh",
          width: "100vw",
        }}
      >
        <Header />
        <Profileheader />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 10,
          }}
        >
          <div
            style={{
              width: window.screen.width < 600 ? 80 : 140,
              height: window.screen.width < 600 ? 80 : 140,
              borderRadius: "50%",
              backgroundColor: "#FFEDCF",
              textAlign: "center",
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: window.screen.width < 600 ? "36px" : 60,
              color: "#5E6C84",
              paddingTop: window.screen.width < 600 ? 30 : 60,
            }}
          >
            {data.first_name.charAt(0)}{data.surname.charAt(0)}
          </div>
          <p
            style={{
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: window.screen.width < 600 ? "16px" : 19,
              color: "#5E6C84",
              marginTop: 20,
            }}
          >
          {data.first_name} {data.surname}
          </p>
          <p
            style={{
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: window.screen.width < 600 ? "16px" : 19,
              color: "rgba(94, 108, 132, 0.5)",
            }}
          >
            {data.email}
          </p>
        </div>
        <div className="divider"></div>
        <Profile data={data} />
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: "#FAFAFA", height: "100%" }}>
      <Header />
      <Profileheader />
      <div className="auth-component">
        <div
          style={{
            width: "48%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 90,
          }}
        >
          <div
            style={{
              width: 140,
              height: 140,
              borderRadius: "50%",
              backgroundColor: "#FFEDCF",
              textAlign: "center",
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: 60,
              color: "#5E6C84",
              paddingTop: 60,
            }}
          >
            {data.first_name.charAt(0)}{data.surname.charAt(0)}
          </div>
          <p
            style={{
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: 19,
              color: "#5E6C84",
              marginTop: 20,
            }}
          >
            {data.first_name} {data.surname}
          </p>
          <p
            style={{
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: 16,
              color: "rgba(94, 108, 132, 0.5)",
            }}
          >
            {data.email}
          </p>
        </div>
        <div className="divider"></div>
        <Profile data={data} />
      </div>
    </div>
  );
}
