import "date-fns";
import React from "react";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import SignupSelect from "../Utils/CustomSelect";
import SignupSelectCountry from "../Utils/CustomSelectCountry";
import SignupInput from "../Utils/CustomInput";
import SignupInputTag from "../Utils/CustomInputTag";
import data from "../../data";

// material-ui components
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/icons
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  //   KeyboardDatePicker,
} from "@material-ui/pickers";

import { makeStyles } from "@material-ui/core/styles";

import { 
  // DatePicker, 
  KeyboardDatePicker } from "@material-ui/pickers";

import jsonData from "../../data.json";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid #D6D7DC",
    "&:hover": {
      borderBottom: "1px solid green",
    },
    "&$focused": {
      borderBottom: "1px solid green",
    },
  },
  focused: {},
  error: {
    color: "red",
    marginTop: -11,
    paddingLeft: 10,
  },
}));

function SignupForm(props) {
  const classes = useStyles();
  const [state, setState] = React.useState([
    {
      name: "",
    },
  ]);
  const [error, setError] = React.useState({});

  const handleDateChange = (date) => props.handleDateChange(date);

  const handleSelectChange = (event) => props.handleSelectChange(event);

  const handleSelectCountryChange = (event) => {
    const name = event.target.value;
    let result = jsonData.find((data) => data.state.name === name);
    if (name) {
      setState(result.state.locals);
    } else {
      setState([
        {
          name: "",
        },
      ]);
    }
    return props.handleSelectCountryChange(name);
  };

  const getChange = (name, value) => props.handleChange(name, value);
  const getTagChange = (name, value) => props.handleTagChange(name, value);

  const handleConfirmEmailValidation = (name, value) => {
    if (value === props.email) {
      setError({ ...error, confirm_email: null });
    } else {
      setError({ ...error, confirm_email: "confirm email error" });
    }
  };

  const handlePasswordValidation = (name, value) => {
    const expression = /^[a-zA-Z0-9]{6,30}$/;
    if (value.match(expression)) {
      setError({ ...error, password: null });
      props.handleChange(name, value);
    } else {
      setError({ ...error, password: "password error" });
    }
  };

  const handleConfirmPasswordValidation = (name, value) => {
    if (value === props.password) {
      setError({ ...error, confirmPassword: null });
      props.handleChange(name, value);
    } else {
      setError({ ...error, confirmPassword: "confirm password error" });
    }
  };

  const handlePhoneNumberValidation = (name, value) => {
    if (value.match(/^[0]\d{10}$/)) {
      setError({ ...error, phone_number: null });
      props.handleChange(name, value);
    } else {
      setError({ ...error, phone_number: "phone number error" });
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ marginRight: 15, width: "45%" }}>
          <SignupInput
            label={"First Name *"}
            id={"first_name"}
            handleChange={getChange}
          />
        </div>
        <div style={{ width: "50%" }}>
          <SignupInput
            label={"Last Name *"}
            id={"surname"}
            handleChange={getChange}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <SignupSelect
          title={"Gender *"}
          handleChange={handleSelectChange}
          data={data.gender}
          id={"gender"}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableFuture
            variant="inline"
            openTo="year"
            format="dd/MM/yyyy"
            margin="normal"
            label="Date of birth *"
            views={["year", "month", "date"]}
            placeholder="dd/mm/yyyy"
            value={props.selectedDate}
            onChange={handleDateChange}
            className={classes.root}
            style={{
              width: "50%",
              fontSize: "16px",
              fontWeight: "normal",
              fontFamily: "Rubik",
              fontStyle: "normal",
              color: "rgba(94, 108, 132, 0.5)",
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <SignupInput
        label={"Email Address *"}
        id={"email"}
        handleChange={getChange}
      />
      <SignupInput
        error={error.confirm_email && true}
        label={"Confirm Email Address *"}
        id={"confirm_email"}
        handleChange={handleConfirmEmailValidation}
      />
      {error.confirm_email && (
        <p className={classes.error}>Email addresses do not match</p>
      )}
      <SignupInput
        error={error.phone_number && true}
        label={"Phone Number *"}
        id={"phone_number"}
        handleChange={handlePhoneNumberValidation}
      />
      {error.phone_number && (
        <p className={classes.error}>
          Phone number must start with "0" and must be 11 digits long
        </p>
      )}
      <SignupInput
        label={"Address *"}
        id={"address"}
        handleChange={getChange}
      />
      <CustomInput
        error={error.password && true}
        labelText="Password *"
        id="password"
        formControlProps={{
          fullWidth: true,
          onChange: (event) =>
            handlePasswordValidation("password", event.target.value),
        }}
        inputProps={{
          type: "password",
          endAdornment: (
            <InputAdornment position="end">
              <LockOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
      {error.password && (
        <p className={classes.error}>
          Password must be at least 6 characters long
        </p>
      )}
      <CustomInput
        error={error.confirmPassword && true}
        labelText="Confirm Password *"
        id="confirmPassword"
        formControlProps={{
          fullWidth: true,
          onChange: (event) =>
            handleConfirmPasswordValidation(
              "confirmPassword",
              event.target.value
            ),
        }}
        inputProps={{
          type: "password",
          endAdornment: (
            <InputAdornment position="end">
              <LockOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
      {error.confirmPassword && (
        <p className={classes.error}>Passwords does not match</p>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 5,
          marginBottom: 30,
        }}
      >
        <SignupSelectCountry
          title={"State *"}
          handleChange={handleSelectCountryChange}
          data={jsonData}
          id={"state"}
        />
        <SignupSelect
          title={"LGA *"}
          handleChange={handleSelectChange}
          data={state}
          id={"lga"}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <div style={{ marginRight: 15, width: "45%",  }}>
          <SignupInputTag
            label={"Spoken Languages *"}
            id={"spoken_languages"}
            handleChange={getTagChange}
          />
        </div>
        <SignupSelect
          title={"Highest Qualification*"}
          handleChange={handleSelectChange}
          data={data.qualifications}
          id={"highest_education"}
        />
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "#F8F8F9",
          padding: 13,
          borderRadius: 4,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              onChange={() => props.handleToggle(21)}
              name="checked"
              style={{ color: "#DADADA" }}
            />
          }
          label="By signing up, I agree to the privacy policy and terms of use"
        />
      </div>
      <Button
        id="login-button"
        onClick={() => props.handleSignUp()}
        type="button"
        style={{ backgroundColor: "#6FCF97", marginTop: 15 }}
        size="lg"
      >
        Start Volunteering
      </Button>
      <div
        style={{
          marginTop: "2%",
          display: "flex",
          justifyContent: "center",
          fontSize: "13px",
          fontWeight: "normal",
          fontFamily: "Rubik",
          fontStyle: "normal",
          color: "#5E6C84",
        }}
      >
        Already have an account?
        <Link to={"/login"} id="signup-link">
          Sign In
        </Link>
      </div>
    </div>
  );
}

export default SignupForm;
