import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 30,
  },
  wrapper: {
    width: 200 + theme.spacing(2),
  },
  paper: {
    zIndex: 10,
    position: "relative",
    margin: theme.spacing(1),
  },
  icon: {
    height: 20,
    width: 40,
    color: "#6FCF97",
  },
}));

function Profile(props) {
  const { history } = props;
  const classes = useStyles();

  const data = JSON.parse(localStorage.getItem("data"));

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleProfileEdit = () => {
    history.push("/edit-profile");
  };

  const handleLogout = () => {
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("data");
    history.replace("/login");
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
    }
  }

  const getIcon = () =>
    window.screen.width > 600 ? (
      <ArrowDropDownIcon />
    ) : (
      <MenuOutlinedIcon className={classes.icon}  />
    );

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div
          onClick={handleChange}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: window.screen.width > 600 && "15%",
            width: window.screen.width > 600 ? "100%" : "55vw",
            paddingLeft: window.screen.width > 600 ? 35 : "25%",
            textAlign: "center",
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "13px",
            color: "#344563",
          }}
        >
          {data.first_name} {data.surname.charAt(0)}.
          <div
            style={{
              width: 30,
              height: 30,
              borderRadius: "50%",
              backgroundColor: "#FFEDCF",
              marginLeft: 12,
              marginRight: 5,
              textAlign: "center",
              padding: 3.5,
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "13px",
              color: "#344563",
            }}
          >
            {data.first_name.charAt(0)}{data.surname.charAt(0)}
          </div>
          {getIcon()}
        </div>
        <Slide direction="left" in={checked} mountOnEnter unmountOnExit>
          <Paper elevation={4} className={classes.paper}>
            <MenuList onKeyDown={handleListKeyDown}>
              <MenuItem onClick={handleProfileEdit}>Edit Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </MenuList>
          </Paper>
        </Slide>
      </div>
    </div>
  );
}

Profile.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Profile);
