import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import data from "../../data";
import Card from "./CategoriesCard";
import Header from "./CategoriesHeader"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function Categories() {
  const classes = useStyles();
  return (
    <div
      style={{
        width: "100vw",
        backgroundColor: "#FAFAFA",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "7vh",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          padding: window.screen.width > 600 ? "3%" : null,
          paddingRight: window.screen.width > 600 ? "5%" : null,
          paddingLeft: window.screen.width > 600 ? "5%" : null,
        }}
      >
        <Header />
        <Grid container className={classes.root} xs={1} sm={12} md={12}>
          {data.categories.map((info, index) =>
            index < 6 ? (
              <Grid
                key={info.id}
                item
                style={{ display: "flex", justifyContent: "center" }}
                xs={1}
                sm={6}
                md={6}
                lg={4}
              >
                <Card id={info.id} title={info.title} />
              </Grid>
            ) : null
          )}
        </Grid>
        <Link style={{ marginBottom: 30 }} to={"/"}>
          <Button
            type="button"
            size="lg"
            style={{
              backgroundColor: "white",
              color: "#6FCF97",
              border: "1px solid #6FCF97",
              borderRadius: "5px",
            }}
          >
            View more opportunities
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default Categories;
